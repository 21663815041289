import RailsUJS from '@rails/ujs';
import anime from 'animejs';

global.$ = require('jquery');

RailsUJS.start();

const MOBILE_HEADER_EL = document.querySelector('header#mobile-header');
const DESKTOP_HEADER_EL = document.querySelector('header#desktop-header');

const CALLBACK_FORM_SELECTOR = '#callback-form';
const CALLBACK_BUTTON_SELECTOR = '#callback-form-submit';
const CALLBACK_SUCCESS_SELECTOR = '#callback-form--success';
const CALLBACK_FAILURE_SELECTOR = '#callback-form--error';
const CALLBACK_FAILURE_MESSAGE_SELECTOR = '#callback-form--error-message';

const ZERO_HEIGHT_CLASS = 'h-0';
const DEFAULT_HEADER_HEIGHT_CLASS = 'h-24';
const ZERO_OPACITY_CLASS = 'opacity-0';
const FULL_OPACITY_CLASS = 'opacity-100';
const SHADOW_CLASS = 'shadow-lg';

const burger = document.querySelector('#burger');
const menu = document.querySelector('#burger-menu');

const BOOK_DEMO_MODAL = document.querySelector('#book-demo-modal');
const BOOK_DEMO_MODAL_BACKDROP = document.querySelector('#book-demo-modal--backdrop');
const BOOK_DEMO_MODAL_PANEL = document.querySelector('#book-demo-modal--panel');
const BOOK_DEMO_MODAL_CLOSE_BUTTONS = document.querySelectorAll('.book-demo-modal--close');
const BOOK_DEMO_BUTTONS = document.querySelectorAll('.book-demo-button');

const callbackForm = document.querySelector(CALLBACK_FORM_SELECTOR);
const callbackButton = document.querySelector(CALLBACK_BUTTON_SELECTOR);

const main = document.querySelector('main');

const WORKER_FEATURES_GRID_SELECTOR = '#worker-features-grid';

const INDUSTRIES_SELECTOR = {
  IMAGES: '.skill-set-image',
  GRADIENT: '#skill-set-gradient',
  LEFT_BUTTON: '#skill-set-left',
  RIGHT_BUTTON: '#skill-set-right',
  DESCRIPTIONS: '.skill-set-desc',
};

const TESTIMONIALS_SELECTOR = {
  VIDEOS: '.testimonial',
  NEXT_BUTTON: '#testimonial-next',
  PREV_BUTTON: '#testimonial-prev',
};

/**
 * @param {HTMLElement} node
 * @param {number} currentScrollPosition
 */
const toggleHeaderShadow = (node, currentScrollPosition) => {
  if (!node) return;

  if (currentScrollPosition > window.innerHeight) {
    node.classList.add(SHADOW_CLASS);
  }
  else {
    node.classList.remove(SHADOW_CLASS);
  }
};

/**
 * @param {HTMLElement} node
 * @param {number} delta
 */
const toggleHeaderVisibility = (node, delta) => {
  if (!node) return;

  if (delta > 0) {
    node.classList.add(FULL_OPACITY_CLASS);
    node.classList.add(DEFAULT_HEADER_HEIGHT_CLASS);
    node.classList.remove(ZERO_OPACITY_CLASS);
    node.classList.remove(ZERO_HEIGHT_CLASS);
  }
  else {
    node.classList.add(ZERO_HEIGHT_CLASS);
    node.classList.add(ZERO_OPACITY_CLASS);
    node.classList.remove(FULL_OPACITY_CLASS);
    node.classList.remove(DEFAULT_HEADER_HEIGHT_CLASS);
  }
};

let prevScrollPosition = main.scrollTop;
let justScrolled = false;

const handleScroll = () => {
  const currentScrollPosition = main.scrollTop;
  if (currentScrollPosition < 0) return;

  toggleHeaderShadow(MOBILE_HEADER_EL, currentScrollPosition);
  toggleHeaderShadow(DESKTOP_HEADER_EL, currentScrollPosition);
  toggleHeaderVisibility(MOBILE_HEADER_EL, prevScrollPosition - currentScrollPosition);
  toggleHeaderVisibility(DESKTOP_HEADER_EL, prevScrollPosition - currentScrollPosition);
  prevScrollPosition = currentScrollPosition;
};

main.onscroll = () => {
  justScrolled = true;
};

setInterval(() => {
  if (justScrolled) {
    justScrolled = false;
    handleScroll();
  }
}, 120);

const animateMenu = (props) => {
  anime({
    targets: '#burger-menu li',
    delay: anime.stagger(100), // increase delay by 100ms for each elements.
    ...props,
  });
};

burger.addEventListener('click', () => {
  const isClosing = burger.classList.contains('open');
  burger.classList.toggle('open');

  if (isClosing) {
    document.body.style.overflowY = 'visible';
    menu.classList.add('opacity-0');
    animateMenu({
      opacity: [1, 0],
      translateY: -10,
    });
    setTimeout(() => menu.classList.add('hidden'), 600);
  }
  else {
    menu.classList.remove('hidden');
    document.body.style.overflowY = 'hidden';
    menu.classList.remove('opacity-0');
    animateMenu({
      opacity: [0, 1],
      translateY: 10,
    });
  }
});

if (window.screen.width > 1024) {
  MOBILE_HEADER_EL.remove();

  const industryGradient = document.querySelector(INDUSTRIES_SELECTOR.GRADIENT);
  if (industryGradient) {
    industryGradient.style.backgroundImage = 'linear-gradient(90deg, #0351FF, transparent 70%)';
  }
}
else {
  DESKTOP_HEADER_EL.remove();
  menu.classList.add('hidden');

  const industryGradient = document.querySelector(INDUSTRIES_SELECTOR.GRADIENT);
  if (industryGradient) {
    industryGradient.style.backgroundImage = 'linear-gradient(180deg, #0351FF, transparent 60%)';
  }

  const grid = document.querySelector(WORKER_FEATURES_GRID_SELECTOR);
  if (grid) {
    for (let i = 0; i < grid.children.length; i += 1) {
      if ((i + 1) % 4 === 0) {
        grid.insertBefore(grid.children[i], grid.children[i - 1]);
      }
    }
  }
}

$(CALLBACK_FORM_SELECTOR)
  .on('ajax:send', () => {
    callbackButton.disabled = true;
    callbackButton.innerHTML = 'Loading...';
  })
  .on('ajax:success', (evt) => {
    callbackButton.disabled = false;
    const side = document.querySelector(CALLBACK_SUCCESS_SELECTOR);
    callbackForm.classList.add('hidden');
    side.classList.remove('hidden');
    side.classList.remove('opacity-0');
  })
  .on('ajax:error', (evt) => {
    callbackButton.disabled = false;
    const side = document.querySelector(CALLBACK_FAILURE_SELECTOR);
    callbackForm.classList.add('hidden');
    side.classList.remove('hidden');
    side.classList.remove('opacity-0');

    const [data] = evt.detail;
    document.querySelector(CALLBACK_FAILURE_MESSAGE_SELECTOR).innerHTML = data.errors[0].message;
  });

BOOK_DEMO_BUTTONS.forEach((button) => {
  button.addEventListener('click', () => {
    BOOK_DEMO_MODAL.classList.remove('hidden');
    document.body.style.overflowY = 'hidden';
    setTimeout(() => {
      BOOK_DEMO_MODAL_BACKDROP.classList.add('opacity-100');
      BOOK_DEMO_MODAL_PANEL.classList.add('opacity-100', 'translate-y-0', 'sm:scale-100');
    }, 1);
  });
});

BOOK_DEMO_MODAL_CLOSE_BUTTONS.forEach((button) => {
  button.addEventListener('click', () => {
    BOOK_DEMO_MODAL_BACKDROP.classList.remove('opacity-100');
    document.body.style.overflowY = 'auto';
    BOOK_DEMO_MODAL_PANEL.classList.remove('opacity-100', 'translate-y-0', 'sm:scale-100');
    setTimeout(() => {
      BOOK_DEMO_MODAL.classList.add('hidden');
    }, 1);
  });
});

let activeIndustry = 0;
const INDUSTRIES_COUNT = document.querySelectorAll(INDUSTRIES_SELECTOR.IMAGES).length;

const toggleOpacity = (node) => {
  if (!node) return;
  node.classList.toggle('opacity-0');
};

const updateActiveIndustryByDelta = (delta) => {
  const images = document.querySelectorAll(INDUSTRIES_SELECTOR.IMAGES);
  const descriptions = document.querySelectorAll(INDUSTRIES_SELECTOR.DESCRIPTIONS);
  toggleOpacity(images[activeIndustry]);
  toggleOpacity(descriptions[activeIndustry]);

  activeIndustry = (activeIndustry + delta + INDUSTRIES_COUNT) % INDUSTRIES_COUNT;
  toggleOpacity(images[activeIndustry]);
  toggleOpacity(descriptions[activeIndustry]);
};

document.querySelector(INDUSTRIES_SELECTOR.LEFT_BUTTON)?.addEventListener('click', () => {
  updateActiveIndustryByDelta(-1);
});

document.querySelector(INDUSTRIES_SELECTOR.RIGHT_BUTTON)?.addEventListener('click', () => {
  updateActiveIndustryByDelta(1);
});

toggleOpacity(document.querySelector(INDUSTRIES_SELECTOR.IMAGES));
toggleOpacity(document.querySelector(INDUSTRIES_SELECTOR.DESCRIPTIONS));

let activeTestimonial = 0;
const TESTIMONIALS_COUNT = document.querySelectorAll(TESTIMONIALS_SELECTOR.VIDEOS).length;
const togglePosition = (node) => {
  if (!node) return;

  // this is because the youtube player needs to be tricked into thinking its refreshing
  node.src = node.src;

  if (node.classList.contains('hidden')) {
    node.classList.remove('hidden');
    node.classList.add('absolute');
  }
  else {
    node.classList.remove('absolute');
    node.classList.add('hidden');
  }
};

const updateActiveTestimonialByDelta = (delta) => {
  const videos = document.querySelectorAll(TESTIMONIALS_SELECTOR.VIDEOS);
  togglePosition(videos[activeTestimonial]);

  activeTestimonial = (activeTestimonial + delta + TESTIMONIALS_COUNT) % TESTIMONIALS_COUNT;
  togglePosition(videos[activeTestimonial]);
};

document.querySelector(TESTIMONIALS_SELECTOR.NEXT_BUTTON)?.addEventListener('click', () => {
  updateActiveTestimonialByDelta(1);
});

document.querySelector(TESTIMONIALS_SELECTOR.PREV_BUTTON)?.addEventListener('click', () => {
  updateActiveTestimonialByDelta(-1);
});

togglePosition(document.querySelector(TESTIMONIALS_SELECTOR.VIDEOS));
